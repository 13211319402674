import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {CoursesWidget} from '../backoffice/CoursesWidget'
import {ExamsWidget} from '../backoffice/ExamsWidget'
import {StudentsWidget} from '../backoffice/CourseDetail/StudentsWidget'
import {BrowseMyCoursesWidget} from '../backoffice/BrowseMyCoursesWidget'
import {BrowsePendingCoursesWidget} from '../backoffice/BrowsePendingCoursesWidget'
import {BrowseAvailableCoursesWidget} from '../backoffice/BrowseAvailableCoursesWidget'
import {ExamStudentsWidget} from '../backoffice/ExamStudentsWidget'
import {Homepage} from '../backoffice/Homepage'
import {ChangeExamDateWidget} from '../backoffice/ChangeExamDateWidget'
import CourseDetail from '../backoffice/CourseDetail/CourseDetail'
import { ExamStudentsDetail } from '../backoffice/ExamStudentsDetail'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route
          path='manage-courses'
          element={<CoursesWidget className='card-xxl-stretch mb-5 mb-xxl-8' />}
        />
        <Route
          path='browse-my-courses'
          element={<BrowseMyCoursesWidget className='card-xxl-stretch mb-5 mb-xxl-8' />}
        />
        <Route
          path='browse-available-courses'
          element={<BrowseAvailableCoursesWidget className='card-xxl-stretch mb-5 mb-xxl-8' />}
        />
        <Route
          path='browse-pending-courses'
          element={<BrowsePendingCoursesWidget className='card-xxl-stretch mb-5 mb-xxl-8' />}
        />
        <Route
          path='exam-students'
          element={<ExamStudentsWidget className='card-xxl-stretch mb-5 mb-xxl-8' />}
        />
        <Route
          path='change-exam-date-widget'
          element={<ChangeExamDateWidget className='card-xxl-stretch mb-5 mb-xxl-8' />}
        />
        <Route
          path='course-detail'
          element={<CourseDetail />}
        />

        <Route
          path='exam-students-detail'
          element={<ExamStudentsDetail className={''} />}
        />
        <Route path='dashboard' element={<Homepage className={''} />} />
        {/* Lazy Modules */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

/*const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}*/

export {PrivateRoutes}
