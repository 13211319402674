import React, { useRef, useState } from 'react';
import axios from 'axios';
import { CourseType } from '../../CoursesWidget';
import { getCourseMaterials, postMaterial } from '../../api/ApiCalls';
import { useQuery, useQueryClient } from 'react-query';

type Props = {
  course: CourseType
}

const getDate = ():string => {
  return new Date().toLocaleDateString('sk-sk', { weekday:"long", year:"numeric", month:"short", day:"numeric", hour:"2-digit", minute:"2-digit", second:"2-digit"}) 
}

const MaterialUpload: React.FC<Props> = (course) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [materialName, setMaterialName] = useState("Materiál z " + getDate());
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = (event: any) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if(selectedFile == null) {
      alert("Nesprávny súbor")
      return;
    }
    if(materialName == null || materialName == '') {
      alert("Nesprávny názov")
      return;
    }
    await postMaterial(selectedFile, materialName, course.course._key);
    alert("Hotovo!");
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
      setSelectedFile(null);
    }
  };

  const handleTextChange = (event: any) => {
    setMaterialName(event.target.value);
  };

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">Nahrať materiál</h3>
        <div className="mb-3">
          <label htmlFor="fileInput" className="form-label">Vyberte súbor</label>
          <input
            type="file"
            id="fileInput"
            ref={fileInputRef}
            onChange={handleFileUpload}
            className="form-control"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="materialName" className="form-label">Zadajte názov materiálu</label>
          <input
            type="text"
            id="materialName"
            value={materialName}
            onChange={handleTextChange}
            className="form-control"
            placeholder="Zadaj meno materiálu"
          />
        </div>
        <button
          onClick={handleUpload}
          className="btn btn-primary"
        >
          Nahrať
        </button>
      </div>
    </div>
  );
};

export { MaterialUpload }