import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { newCourse, patchCourse } from './api/ApiCalls';
import { CourseType } from './CoursesWidget';
import { useNavigate } from 'react-router-dom';
import { fixDate } from './utils';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Meno je povinné pole'),
  capacity: Yup.number().required('Kapacita je povinná'),
  startDate: Yup.date().required('Dátum začiatku je povinný'),
  registrationEnd: Yup.date().required('Uzávierka je povinná'),
});

type NewCourseType = {
    name: string
    capacity: number
    startDate: Date
    registrationEnd: Date
    isHidden: boolean
}

type Props = {
  course?: CourseType
}

const MyFormComponent: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  const handleSubmit = async (values: any) => {
    const course = values as NewCourseType;
    course.isHidden = false;
    if((props.course != null)) {
      await patchCourse(props.course._key, course);
    }
    else {
      await newCourse(course);
    }
    navigate('/manage-courses');
    window.location.reload();
  };

  return (
    <div className="container">
      <Formik
        initialValues={{
          name: ((props.course != null) ? props.course.name : 'Kurz'),
          capacity: ((props.course != null) ? props.course.capacity : 10),
          startDate: ((props.course != null) ? fixDate(props.course.startDate) : ''),
          registrationEnd: ((props.course != null) ? fixDate(props.course.registrationEnd) : ''),
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Názov kurzu
              </label>
              <Field
                type="text"
                name="name"
                id="name"
                className="form-control"
              />
              <ErrorMessage name="name" component="div" className="text-danger" />
            </div>

            <div className="mb-3">
              <label htmlFor="capacity" className="form-label">
                Kapacita
              </label>
              <Field
                type="number"
                name="capacity"
                id="capacity"
                className="form-control"
              />
              <ErrorMessage
                name="capacity"
                component="div"
                className="text-danger"
              />
            </div>

            <div className="mb-3">
              <label htmlFor="startDate" className="form-label">
                Dátum začiatku
              </label>
              <Field
                type="date"
                name="startDate"
                id="startDate"
                className="form-control"
              />
              <ErrorMessage
                name="startDate"
                component="div"
                className="text-danger"
              />
            </div>

            <div className="mb-3">
              <label htmlFor="registrationEnd" className="form-label">
                Uzávierka
              </label>
              <Field
                type="date"
                name="registrationEnd"
                id="registrationEnd"
                className="form-control"
              />
              <ErrorMessage
                name="registrationEnd"
                component="div"
                className="text-danger"
              />
            </div>

            <button type="submit" className="btn btn-primary">
              Vytvoriť
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MyFormComponent;
