import React from 'react';
import { deleteCourse } from '../api/ApiCalls';
import { useNavigate } from 'react-router-dom';

type Props = {
    courseKey: string
}

const DeleteCourseButton: React.FC<Props> = (props) => {

    const navigate = useNavigate();

    const handleDelete = async () => {
        const confirmation = window.confirm(`Naozaj chcete zmazať kurz?`);
        if (confirmation) {
            await deleteCourse(props.courseKey);
            alert('Kurz bol zmazaný.');
            navigate('/manage-courses');
            window.location.reload();
        }
    };

    return (
        <div>
            <button
                type="button"
                className="btn btn-danger"
                onClick={handleDelete}
            >
                Zmazať kurz
            </button>
        </div>
    );
};

export default DeleteCourseButton;
