/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../_metronic/helpers'
import {useQuery, useQueryClient} from 'react-query'
import {fetchAvailableCourses, fetchMyCourses, getPdf, registerForCourse} from './api/ApiCalls'
import {Link} from 'react-router-dom'
import { getSpinner } from './Misc'

type Props = {
  className: string
}

export const formatDate = (startDate: Date) => {
  const date = new Date(startDate)
  return new Intl.DateTimeFormat('sk-SK', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(date)
}

interface CourseType {
  _key: string
  _id: string
  _rev: string
  createdAt: Date
  updatedAt: Date
  name: string
  capacity: number
  startDate: Date
}

type MyCoursesModel = {
  enrolledCourses: CourseType[]
  pendingCourses: CourseType[]
}

const BrowseAvailableCoursesWidget: React.FC<Props> = ({className}) => {
  const queryClient = useQueryClient()

  const refresh = () => {
    queryClient.invalidateQueries({queryKey: ['browseCoursesData']})
    queryClient.invalidateQueries({queryKey: ['MyCoursesData']})
  }

  const {isLoading, data, isFetching} = useQuery<CourseType[], Error>({
    queryKey: ['browseCoursesData'],
    queryFn: () => fetchAvailableCourses(),
  })

  const myCoursesQuery = useQuery<MyCoursesModel, Error>({
    queryKey: ['MyCoursesData'],
    queryFn: () => fetchMyCourses(),
  })

  if (isLoading || isFetching) return getSpinner();

  //console.log(data)

  const formatCourses = (data: any) => {
    if (data == null) {
      return <></>
    }
    return data.map((course: CourseType) => (
      <tr key={course._id}>
        <td>
          <div className='text-dark fw-bold text-hover-primary fs-6'>{course.name}</div>
        </td>
        <td>
          <div className='text-dark fw-bold text-hover-primary fs-6'>{course.capacity}</div>
        </td>
        <td>{formatDate(course.startDate)}</td>
        <td>{formatDate(course.createdAt)}</td>
        <td>{formatDate(course.updatedAt)}</td>

        <td className='text-end'>
          <button
            onClick={async () => {
              if (window.confirm(`Podať prihlášku na kurz "${course.name}"?`)) {
                await registerForCourse(course._key)
                refresh()
              }
            }}
            className='btn btn-primary btn-active-color-light btn-sm'
            title='Prihlásiť sa na kurz'
          >
            <KTSVG path='/media/icons/duotune/general/gen055.svg' className='svg-icon-3' />
            Podať prihlášku
          </button>
        </td>
      </tr>
    ))
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Dostupné kurzy</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Zoznam dostupných kurzov</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>Názov kurzu</th>
                <th className='min-w-140px'>Kapacita</th>
                <th className='min-w-120px'>Dátum</th>
                <th className='min-w-120px'>Vytvorené</th>
                <th className='min-w-120px'>Aktualizované</th>
                <th className='min-w-100px text-end'>Akcie</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>{formatCourses(data)}</tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
    </div>
  )
}

export {BrowseAvailableCoursesWidget}
