type Props = {
  className: string
}

const Homepage: React.FC<Props> = ({className}) => {
  return (
    <>
      <div className='card-body p-9'>
        <div className='fs-3 fw-bolder text-dark'>Kurzy</div>
        <p className='text-gray-400 fw-bold fs-5 mt-1 mb-7'>
          Začnite kliknutím na požadovanú akciu vľavo.
        </p>
      </div>
    </>
  )
}

export {Homepage}
