/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {useQuery, useQueryClient} from 'react-query'
import {
  fetchCandidates,
  putUserState,
} from '../api/ApiCalls'
import { CourseType } from '../CoursesWidget'
import { getSpinner } from '../Misc'

type Props = {
  className: string,
  course: CourseType
}

type StudentsType = {
  candidates: StudentType[]
}

type EnrolledCourse = {
  _key: string
  name: string
}

interface StudentType {
  _key: string
  email: string
  firstName: string
  lastName: string
  enrolledCourses: EnrolledCourse[]
}

const StudentsWidget: React.FC<Props> = ({className, course}) => {
  const queryClient = useQueryClient()

  const refresh = () => {
    queryClient.invalidateQueries({queryKey: ['studentsData']})
  }

  const {isLoading, data, isFetching} = useQuery<StudentsType, Error>({
    queryKey: ['studentsData'],
    queryFn: () => fetchCandidates(course._key),
  })

  if (isLoading || isFetching) return getSpinner()

  const formatCandidates = (data: any) => {
    if (data == null || data.length === 0) {
      return <></>
    }
    return data.candidates.map((candidate: StudentType) => (
      <tr key={candidate._key}>
        <td><span className='fw-bold fs-6'>{candidate.firstName}</span></td>
        <td><span className='fw-bold fs-6'>{candidate.lastName}</span></td>
        <td><span className='fw-bold fs-6'>{candidate.email}</span></td>

        <td className='text-end'>
          <button
            onClick={async () => {
              if (
                window.confirm(`Naozaj akceptovat ${candidate.firstName} ${candidate.lastName}?`)
              ) {
                await putUserState(course._key, candidate._key, {newState: 2})
                refresh()
              }
            }}
            className='btn btn-success btn-sm'
            title='Akceptovať študenta'
          >
            <KTSVG path='/media/icons/duotune/general/gen035.svg' className='svg-icon-3' />

            Akceptovať
          </button>
          &nbsp;
          <button
            onClick={async () => {
              if (
                window.confirm(`Naozaj odmietnuť ${candidate.firstName} ${candidate.lastName}?`)
              ) {
                await putUserState(course._key, candidate._key, {newState: 0})
                refresh()
              }
            }}
            className='btn btn-danger btn-sm'
            title='Odmietnuť študenta'
          >
            <KTSVG path='/media/icons/duotune/general/gen034.svg' className='svg-icon-3' />

            Odmietnuť
          </button>
          {(candidate.enrolledCourses.length != 0 ? <>
            <br/>
            <label className='col-lg-4 fw-bold text-muted'>Taktiež prihlásený na: </label>
            <br/>
            {candidate.enrolledCourses.map((course: EnrolledCourse) => (
              <React.Fragment key={course._key}>
                <span className="badge badge-light-primary">{course.name}</span>
              </React.Fragment>
            ))}
          </> : <></>)}
        </td>
      </tr>
    ))
  }

  return (
    <div className={`card ${className}`}>
      {/* Course select box*/}

      {/* Course select box end*/}
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Pridať kandidatov</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            Zoznam skusok pre kurz "{course.name}"
          </span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>Meno</th>
                <th className='min-w-150px'>Priezvisko</th>
                <th className='min-w-150px'>E-mail</th>
                <th className='min-w-100px text-end'>Akcie</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {formatCandidates(data)}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      <br />
      {/* begin::Body */}
    </div>
  )
}

export {StudentsWidget}
