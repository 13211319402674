/* eslint-disable react/jsx-no-target-blank */
import {SidebarMenuItem} from './SidebarMenuItem'
import {isAdmin} from '../../../../../app/backoffice/Helpers'
import {useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const {currentUser} = useAuth()
  return (
    <>
      {isAdmin(currentUser) ? (
        <SidebarMenuItem
          to='/manage-courses'
          icon='/media/icons/duotune/art/art002.svg'
          title={'Správa kurzov'}
          fontIcon='bi-app-indicator'
        />
      ) : (<>
        <SidebarMenuItem
          to='/browse-available-courses'
          icon='/media/icons/duotune/art/art002.svg'
          title={'Dostupné kurzy'}
          fontIcon='bi-app-indicator'
        />
        <SidebarMenuItem
          to='/browse-pending-courses'
          icon='/media/icons/duotune/general/gen012.svg'
          title={'Čakajúce kurzy'}
          fontIcon='bi-app-indicator'
        />
        <SidebarMenuItem
          to='/browse-my-courses'
          icon='/media/icons/duotune/general/gen056.svg'
          title={'Moje kurzy'}
          fontIcon='bi-app-indicator'
        /></>
      )}
    </>
  )
}

export {SidebarMenuMain}
