import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { KTSVG } from '../../../_metronic/helpers';
import { CourseType } from '../CoursesWidget';
import DeleteCourseButton from './DeleteCourseButton';
import NewCourseWidget from '../NewCourseWidget';
import { useAuth } from '../../modules/auth';
import { isAdmin } from '../Helpers';

type Props = {
  course: CourseType
}

export const formatDate = (rawDate: Date) => {
  const date = new Date(rawDate)
  var now_utc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
                date.getUTCDate(), date.getUTCHours(),
                date.getUTCMinutes(), date.getUTCSeconds());
  return new Intl.DateTimeFormat('sk-SK', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(now_utc)
}

const CourseDetail: React.FC<Props> = ({ course }) => {
  const { currentUser } = useAuth();
  return (
    <>
      <div>
        <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className="modal-title">Úprava kurzu</h5>
              </div>
              <div className='modal-body'>
                {<NewCourseWidget course={course} />}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header cursor-pointer'>
          <div className='card-title' style={{boxSizing: 'border-box', width: '100%'}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
              <span className='align-middle'>
                <h2 style={{height:'40px', textAlign:'center', lineHeight: '40px'}}>Detail kurzu</h2>
              </span>
              { isAdmin(currentUser) ? <>
              <a
                    href='#'
                    className='btn btn-primary ms-auto'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_1'
                  >
                    Upraviť
              </a>
              &nbsp;
              <DeleteCourseButton courseKey={course._key} />
              </>
              : <></>}
            </div>
        </div>
        </div>

        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Názov</label>

            <div className='col-lg-8'>
              <span className='fw-bolder fs-6 text-dark'>{course.name}</span>
            </div>

          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Kapacita</label>

            <div className='col-lg-8 fv-row'>
              <span className='fw-bold fs-6'>{course.capacity}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Dátum začiatku</label>
            <div className='col-lg-8'>
              <span className='fw-bold fs-6'>{formatDate(course.startDate)}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Uzávierka</label>
            <div className='col-lg-8'>
              <span className='fw-bold fs-6'>{formatDate(course.registrationEnd)}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Vytvorené</label>

            <div className='col-lg-8'>
              <span className='fs-6'>{formatDate(course.createdAt)}</span>
            </div>
          </div>

          <div className='row mb-7'>
            <label className='col-lg-4 fw-bold text-muted'>Naposledy upravené</label>

            <div className='col-lg-8'>
              <span className='fs-6'>{formatDate(course.updatedAt)}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseDetail;
