/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {KTSVG} from '../../_metronic/helpers'
import {useQuery, useQueryClient} from 'react-query'
import {deleteCourse, fetchCoursesByPage, newCourse, patchCourse} from './api/ApiCalls'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import { getSpinner } from './Misc'
import NewCourseWidget from './NewCourseWidget'

type Props = {
  className: string
}

export const formatDate = (startDate: Date) => {
  const date = new Date(startDate)
  return new Intl.DateTimeFormat('sk-SK', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }).format(date)
}

export type CourseType = {
  _key: string
  _id: string
  _rev: string
  createdAt: Date
  updatedAt: Date
  name: string
  capacity: number
  startDate: Date
  registrationEnd: Date
  isHidden: boolean
}

type CourseModel = {
  totalCount: number
  results: CourseType[]
}

const newCourseSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimálne 3 symboly')
    .max(50, 'Maximálne 50 symbolov')
    .required('Nesprávny názov kurzu'),
  capacity: Yup.number().min(1, 'Minimálny počet je 1').required('Nesprávny počet uchádzačov'),
  startDate: Yup.date().required('Neplatný dátum'),
})

const initialValues = {
  name: 'Kurz',
  capacity: 20,
  startDate: '2023-01-30',
  registrationEnd: '2023-01-30',
}

const CoursesWidget: React.FC<Props> = ({className}) => {
  const queryClient = useQueryClient()

  const refresh = () => {
    queryClient.invalidateQueries({queryKey: ['coursesData']})
  }

  const [page, setPage] = useState(0)

  const formik = useFormik({
    initialValues,
    validationSchema: newCourseSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        await newCourse(values)
        refresh()
      } catch (error) {
        console.error(error)
        setStatus('Údaje nie su v poriadku')
        setSubmitting(false)
      }
    },
  })

  const {isLoading, data, isFetching} = useQuery<CourseModel, Error>({
    queryKey: ['coursesData', page],
    queryFn: () => fetchCoursesByPage(page),
  })

  if (isLoading || isFetching) return getSpinner()

  //if (error) return {"An error has occurred: " + error.message};

  async function patch(key: string, ret: string | null, obj: any) {
    if (ret !== null && ret !== '') {
      await patchCourse(key, obj)
      refresh()
    }
  }

  const formatCourses = (data: any) => {
    if (data == null || data.length === 0) {
      return <></>
    }
    return data.results.map((course: CourseType) => (
      <tr key={course._id}>
        <td>
          <div className='text-dark fw-bold text-hover-primary fs-6'>
            {course.name}
          </div>
        </td>
        <td>
          <div className='text-dark fw-bold text-hover-primary fs-6'>
            {course.capacity}
          </div>
        </td>
        <td>
          {formatDate(course.startDate)}
        </td>
        <td className='text-end'>
          <Link
            to='/course-detail'
            state={{selectedCourse: course}}
            className='btn btn-primary btn-active-color-primary btn-sm'
            title='Prejsť na detaily kurzu'
          >
            <KTSVG path='/media/icons/duotune/general/gen004.svg' className='svg-icon-3' />
            {<span className='indicator-label'>Detail</span>}
          </Link>
        </td>
      </tr>
    ))
  }

  const formatPagination = () => {
    if (data == null) {
      return <></>
    }
    return Array.from(Array(Math.ceil(data.totalCount / 10)), (e, i) => {
      let style = `page-link ${i === page ? 'active' : ''}`
      return (
        <li key={i} className='page-item'>
          <a
            href='#'
            className={style}
            onClick={() => {
              setPage(i)
              refresh()
            }}
          >
            {i + 1}
          </a>
        </li>
      )
    })
  }

  return (
    <div className={`card ${className}`}>
      <div>
        <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
          <div className='modal-dialog'>
            <div className='modal-content'>
              <div className='modal-header'>
              <h5 className="modal-title">Nový kurz</h5>
              </div>
            <div className='modal-body'>
                  
              {<NewCourseWidget/>}
              </div>
              
            </div>
          </div>
        </div>
      </div>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Kurzy</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Zoznam vytvorených kurzov</span>
        </h3>
        <div className='card-toolbar'>
          <a
            href='#'
            //onClick={() => setShowCreateAppModal(true)}
            className='btn btn-sm fw-bold btn-primary'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_1'
          >
            Vytvoriť
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>Názov kurzu</th>
                <th className='min-w-140px'>Kapacita</th>
                <th className='min-w-120px'>Dátum</th>
                <th className='min-w-100px text-end'>Akcie</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>{formatCourses(data)}</tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      <div>
        <ul className='pagination'>{formatPagination()}</ul>
      </div>
      <br />
      {/* begin::Body */}
    </div>
  )
}

export {CoursesWidget}
