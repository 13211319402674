import React, { useState } from 'react';
import { StudentsWidget } from './StudentsWidget';
import { ExamsWidget } from '../ExamsWidget';
import CourseInfo from './CourseInfo';
import { CourseType } from '../CoursesWidget';
import { useLocation } from 'react-router-dom';
import { CourseFilesWidget } from './Materials/CourseFilesWidget';
import { useAuth } from '../../modules/auth';
import { isAdmin } from '../Helpers';


const MetronicCardSwitcher = () => {
  const { currentUser } = useAuth();
  const [activeCard, setActiveCard] = useState(isAdmin(currentUser) ? 1 : 3);
  const location = useLocation();
  const handleCardChange = (cardNumber: number) => {
    setActiveCard(cardNumber);
  };

  const {selectedCourse} = location.state as any

  return (
    <div className="card-switcher">
        <CourseInfo course={selectedCourse}/>
      <div className="btn-group" role="group">
      {isAdmin(currentUser) ? (
        <button
          className={`btn ${activeCard === 1 ? 'btn-primary' : 'btn-secondary'}`}
          onClick={() => handleCardChange(1)}
        >
          Kandidáti
        </button>
      ) : <></>}
        {/*<button
          className={`btn ${activeCard === 2 ? 'btn-primary' : 'btn-secondary'}`}
          onClick={() => handleCardChange(2)}
        >
          Skúšky
        </button>*/}
        <button
          className={`btn ${activeCard === 3 ? 'btn-primary' : 'btn-secondary'}`}
          onClick={() => handleCardChange(3)}
        >
          Materiály
        </button>

      </div>
        <br/>
        <br/>
      <div className="card">
        <div className="card-body">
            
            {activeCard === 1 && <StudentsWidget className='card-xxl-stretch mb-5 mb-xxl-8' course={selectedCourse} />}
            {/*activeCard === 2 && <ExamsWidget className='card-xxl-stretch mb-5 mb-xxl-8' course={selectedCourse} />*/}
            {activeCard === 3 && <CourseFilesWidget course={selectedCourse} />}
        </div>
      </div>
    </div>
  );
};

export default MetronicCardSwitcher;
