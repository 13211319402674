import React from 'react';
import { useQuery, useQueryClient } from "react-query";
import { CourseType } from "../../CoursesWidget";
import { deleteMaterial, getCourseMaterials, getMaterial } from "../../api/ApiCalls";
import { KTSVG } from '../../../../_metronic/helpers';
import { getSpinner } from '../../Misc';
import { humanFileSize } from '../../utils';
import { useAuth } from '../../../modules/auth';
import { isAdmin } from '../../Helpers';


type Props = {
    course: CourseType
}

export interface MaterialsType {
    total_count: number;
    materials: Material[];
}

export interface Material {
    _key: string;
    _id: string;
    _rev: string;
    name: string;
    mimeType: string;
    size: number;
    createdAt: Date;
    updatedAt: Date;
    slug: string;
    extension: string;
}



const MaterialWidget: React.FC<Props> = (course) => {
    const queryClient = useQueryClient();
    const { currentUser } = useAuth();

    const { isLoading, data, isFetching } = useQuery<MaterialsType, Error>({
        queryKey: ['courseMaterials'],
        queryFn: () => getCourseMaterials(course.course._key),
    })

    if (isLoading || isFetching) return getSpinner();


    const formatFiles = (data: MaterialsType | undefined) => {
        if (data == null || data.materials == null) {
            return <></>;
        }
        if(data.materials.length === 0) {
            return (
            <tr>
                <td>
                <span>Pre tento kurz nie su dostupné žiadne materiály</span>
                </td>
            </tr>)
        }
        return (data.materials.map((res => (
            <React.Fragment key={res.createdAt.toString()}>
                <tr>
                    <td>
                        <div className='text-dark fw-bold text-hover-primary fs-6'>
                            {res.name} 
                        </div>
                        <div className='text-dark fst-italic text-hover-primary fs-6'>
                            {res.extension} ({humanFileSize(res.size, true, 2)})
                        </div>
                    </td>

                    <td className='text-end'>
                        <button
                            onClick={async () => {
                                getMaterial(res);
                            }}
                            className='btn btn-primary btn-active-color-light btn-sm'
                            title='Stiahnuť materiál'
                        >
                            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-3' />
                            Stiahnuť materiál
                        </button>
                        {isAdmin(currentUser) ? 
                        <>
                            &nbsp;
                            <button
                                onClick={async () => {
                                    if (window.confirm(`Naozaj vymazať material "${res.name}" ?`)) {
                                        await deleteMaterial(res._key)
                                        queryClient.invalidateQueries({ queryKey: ['courseMaterials'] });
                                    }
                                }}
                                className='btn btn-danger btn-active-color-primary btn-sm'
                                title='Odstrániť material'
                            >
                                <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                                Vymazať
                            </button>
                            
                        
                        </>
                        :<></>}
                    </td>
                </tr>
            </React.Fragment>
        ))))
    }

    return (<><div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
            <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-3 mb-1'>Zoznam materiálov</span>
                <span className='text-muted mt-1 fw-semibold fs-7'>Zoznam dostupných materiálov pre kurz</span>
            </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                    {/* begin::Table head */}
                    <thead>
                        <tr className='fw-bold text-muted'>
                            <th className='min-w-150px'>Názov</th>
                            <th className='min-w-100px text-end'>Akcie</th>
                        </tr>
                    </thead>
                    {/* end::Table head */}
                    {/* begin::Table body */}
                    <tbody>
                        {formatFiles(data)}
                    </tbody>
                    {/* end::Table body */}
                </table>
                {/* end::Table */}
            </div>
            {/* end::Table container */}
        </div>
        {/* begin::Body */}
    </div></>);
}

export { MaterialWidget }