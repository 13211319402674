import axios from 'axios'
import {Logout, getAuth, setAuth, setupAxios} from '../../modules/auth'
import jwt_decode from 'jwt-decode'
import { Material } from '../CourseDetail/Materials/MaterialWidget'

const API_URL = process.env.REACT_APP_COURSES_URL
const AUTH_API_URL = process.env.REACT_APP_AUTH_URL
const STUDENTS_URL = process.env.REACT_APP_STUDENTS_URL

const COURSES_URL = `${API_URL}`
const REFRESH_URL = `${AUTH_API_URL}/refresh`

const refreshToken = async (): Promise<boolean> => {
  try {
    const refreshToken = getAuth()?.refresh_token
    const response = await axios.post(REFRESH_URL, {refresh_token: refreshToken})
    setAuth(response.data)
    setupAxios(axios)
    return true
  } catch (error) {
    if (axios.isAxiosError(error) && error.response != null) {
      Logout()
      return false
    }
    return false
  }
}

const isTokenExpired = () => {
  const accessToken = getAuth()?.access_token
  if (accessToken === undefined) return true
  let decoded: any = jwt_decode(accessToken)
  return decoded.iat > Math.floor(Date.now() / 1000)
}

export const get = async (url: string): Promise<any> => {
  try {
    if (isTokenExpired()) {
      await refreshToken()
    }
    const response = await axios.get(url)
    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response != null && error.response.status === 401) {
      if (await refreshToken()) {
        return await get(url)
      }
    }
  }
}

export const post = async (url: string, payload: any): Promise<any> => {
  try {
    if (isTokenExpired()) {
      await refreshToken()
    }
    const response = await axios.post(url, payload)
    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response != null && error.response.status === 401) {
      if (await refreshToken()) {
        return await post(url, payload)
      }
    }
  }
}

export const put = async (url: string, payload: any): Promise<any> => {
  try {
    if (isTokenExpired()) {
      await refreshToken()
    }
    const response = await axios.put(url, payload)
    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response != null && error.response.status === 401) {
      if (await refreshToken()) {
        return await put(url, payload)
      }
    }
  }
}

export const putWithError = async (url: string, payload: any): Promise<any> => {
  try {
    if (isTokenExpired()) {
      await refreshToken()
    }
    const response = await axios.put(url, payload)
    return response
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response != null && error.response.status === 401) {
      if (await refreshToken()) {
        return await put(url, payload)
      }
    } else {
      return error.response.status
    }
  }
}

export const del = async (url: string): Promise<any> => {
  try {
    if (isTokenExpired()) {
      await refreshToken()
    }
    const response = await axios.delete(url)
    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response != null && error.response.status === 401) {
      if (await refreshToken()) {
        return await del(url)
      }
    }
  }
}

export const patch = async (url: string, payload: any): Promise<any> => {
  try {
    if (isTokenExpired()) {
      await refreshToken()
    }
    const response = await axios.patch(url, payload)
    return response.data
  } catch (error) {
    if (axios.isAxiosError(error) && error.response != null && error.response.status === 401) {
      if (await refreshToken()) {
        return await patch(url, payload)
      }
    }
  }
}

export const postLogout = async () => {
  await post(`${AUTH_API_URL}/logout`, {refresh_token: getAuth()?.refresh_token})
}

export const fetchCourses = async () => {
  return await get(COURSES_URL)
}

export const fetchCoursesByPage = async (page: number) => {
  return await get(`${COURSES_URL}?page=${page}&pageSize=10`)
}

export const newCourse = async (values: any) => {
  return await post(COURSES_URL, values)
}

export const deleteCourse = async (id: string) => {
  return await del(`${COURSES_URL}/${id}`)
}

export const patchCourse = async (id: string, obj: any) => {
  return await patch(`${COURSES_URL}/${id}`, obj)
}

export const fetchCourseExams = async (id: string) => {
  return await get(`${COURSES_URL}/${id}/exams`)
}

export const patchCourseExam = async (id: string, obj: any) => {
  return await patch(`${COURSES_URL}/exams/${id}`, obj)
}

export const newCourseExam = async (id: string, values: any) => {
  return await post(`${COURSES_URL}/${id}/exams`, values)
}

export const deleteCourseExam = async (id: string) => {
  return await del(`${COURSES_URL}/exams/${id}`)
}

export const fetchAvailableCourses = async () => {
  return await get(`${STUDENTS_URL}/available-courses`)
}

export const fetchMyCourses = async () => {
  return await get(`${STUDENTS_URL}/my-courses`)
}

export const registerForCourse = async (id: string) => {
  return await post(`${COURSES_URL}/${id}/register`, {})
}

export const fetchCandidates = async (id: string) => {
  return await get(`${COURSES_URL}/${id}/candidates`)
}

export const putUserState = async (courseId: string, userId: string, obj: any) => {
  return await put(`${COURSES_URL}/${courseId}/user/${userId}/state`, obj)
}

export const getPdf = async (courseId: string) => {
  const auth = getAuth()
  if (auth == null) {
    return
  }
  const authorization = `Bearer ${auth.access_token}`
  const config = {
    headers: {
      Authorization: authorization,
    },
    responseType: 'blob',
  }
  // Workaround for blob response type get
  axios
    .get(`${COURSES_URL}/${courseId}/application-form-document`, config as any)
    .then((response) => {
      const pdf = new File([response.data], courseId + '.pdf')
      const link = document.createElement('a')
      const url = URL.createObjectURL(pdf)

      link.href = url
      link.download = pdf.name
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    })
}

export const getStudentsForExam = async (id: string) => {
  return await get(`${COURSES_URL}/exam/${id}/students`)
}

export const registerForExam = async (id: string) => {
  return await putWithError(`${STUDENTS_URL}/exam/${id}`, {})
}

export const unregisterForExam = async (id: string) => {
  return await del(`${STUDENTS_URL}/exam/${id}`)
}

export const fetchMaterials = async (query: string) => {
  return await get(`${COURSES_URL}/material/list?page=0&pageSize=10&query=${query}`)
}

export const deleteMaterial = async (key: string) => {
  return await del(`${COURSES_URL}/material/${key}`)
}

export const getMaterial = async (material: Material) => {
  const auth = getAuth()
  if (auth == null) {
    return
  }
  const authorization = `Bearer ${auth.access_token}`
  const config = {
    headers: {
      Authorization: authorization,
    },
    responseType: 'blob',
  }
  // Blob response type get
  axios
    .get(`${COURSES_URL}/material/${material._key}/download`, config as any)
    .then((response) => {
      const pdf = new File([response.data], material.slug + '.' + material.extension);
      const link = document.createElement('a')
      const url = URL.createObjectURL(pdf)

      link.href = url
      link.download = pdf.name
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      window.URL.revokeObjectURL(url)
    })
}

export const postMaterial = async (material: any, title: string, courseKey: string) => {
  const formData = new FormData();
  formData.append('file', material as any);
  formData.append('name', title);
  formData.append('courseKey', courseKey);
  await post(COURSES_URL+'/material', formData);
}

export const getCourseMaterials = async (id: string) => {
  return await get(`${COURSES_URL}/${id}/materials`)
}