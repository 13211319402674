/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useQuery} from 'react-query'
import {getStudentsForExam} from './api/ApiCalls'
import {useLocation} from 'react-router-dom'

type Props = {
  className: string
}

type StudentsType = {
  candidates: StudentType[]
}

interface StudentType {
  _key: string
  email: string
  firstName: string
  lastName: string
}

const ExamStudentsWidget: React.FC<Props> = ({className}) => {
  const location = useLocation()
  const {key, courseName, examName} = location.state as any

  const {isLoading, data, isFetching} = useQuery<StudentsType, Error>({
    queryKey: ['studentsExamData'],
    queryFn: () => getStudentsForExam(key),
  })

  if (isLoading || isFetching) return <span>Nacitavanie</span>

  const formatCandidates = (data: any) => {
    if (data == null || data.length === 0) {
      return <></>
    }
    return data.map((candidate: StudentType) => (
      <tr key={candidate._key}>
        <td>{candidate.firstName}</td>
        <td>{candidate.lastName}</td>

        <td className='text-end'>{/*todo*/}</td>
      </tr>
    ))
  }

  return (
    <div className={`card ${className}`}>
      {/* Course select box*/}

      {/* Course select box end*/}
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Zoznam študentov pre skúšku</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            Zoznam študentov pre skúšku {examName} kurzu {courseName}
          </span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>Meno</th>
                <th className='min-w-150px'>Priezvisko</th>
                <th className='min-w-100px text-end'>Akcie</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>{formatCandidates(data)}</tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      <br />
      {/* begin::Body */}
    </div>
  )
}

export {ExamStudentsWidget}
