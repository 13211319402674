/* eslint-disable jsx-a11y/anchor-is-valid */
import {useFormik} from 'formik'
import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import {patchCourseExam} from './api/ApiCalls'
import clsx from 'clsx'

type Props = {
  className: string
}

const schema = Yup.object().shape({
  date: Yup.date().required('Neplatný dátum'),
})

const ChangeExamDateWidget: React.FC<Props> = ({className}) => {
  const location = useLocation()
  const navigate = useNavigate()
  const {key, title, currentDate} = location.state as any

  const initialValues = {
    date: currentDate,
    registrationEnd: currentDate
  }

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      try {
        await patchCourseExam(key, values)
        alert('Hotovo')
        navigate('/manage-courses')
      } catch (error) {
        console.error(error)
        setStatus('Údaje nie su v poriadku')
        setSubmitting(false)
      }
    },
  })

  return (
    <div className={`card ${className}`}>
      {/* Course select box*/}

      {/* Course select box end*/}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <form
          className='form w-100'
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signin_form'
        >
          <div className='modal-header'>
            {/* begin::Heading */}
            <h1 className='text-dark fw-bolder mb-3'>Úprava dátumu pre: {title}</h1>
            {/* begin::Heading */}
          </div>
          <div className='modal-body'>
            {formik.status ? (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>{formik.status}</div>
              </div>
            ) : (
              <></>
            )}

            {/* begin::Form group */}
            <div className='fv-row mb-3'>
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>Dátum</label>
              <input
                type='datetime-local'
                autoComplete='off'
                {...formik.getFieldProps('date')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.date && formik.errors.date,
                  },
                  {
                    'is-valid': formik.touched.date && !formik.errors.date,
                  }
                )}
              />
              {formik.touched.date && formik.errors.date && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    {/*<span role='alert'>{formik.errors.startDate}</span>*/}
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}
            {/* begin::Form group */}
            <div className='fv-row mb-3'>
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>Dátum uzávierky</label>
              <input
                type='datetime-local'
                autoComplete='off'
                {...formik.getFieldProps('registrationEnd')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.registrationEnd && formik.errors.registrationEnd,
                  },
                  {
                    'is-valid': formik.touched.registrationEnd && !formik.errors.registrationEnd,
                  }
                )}
              />
              {formik.touched.registrationEnd && formik.errors.registrationEnd && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    {/*<span role='alert'>{formik.errors.startDate}</span>*/}
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}
          </div>
          <div className='modal-footer'>
            {/* begin::Action */}

            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-primary'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {<span className='indicator-label'>Upraviť</span>}
            </button>

            {/* end::Action */}
          </div>
        </form>
      </div>
      <br />
      {/* begin::Body */}
    </div>
  )
}

export {ChangeExamDateWidget}
