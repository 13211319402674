import { useAuth } from "../../../modules/auth";
import { CourseType } from "../../CoursesWidget";
import { isAdmin } from "../../Helpers";
import { MaterialUpload } from "./MaterialUpload";
import { MaterialWidget } from "./MaterialWidget";

type Props = {
    course: CourseType
}

const CourseFilesWidget: React.FC<Props> = (course) => {
    const { currentUser } = useAuth()
    return (<>
        {isAdmin(currentUser) ? (<>
            <MaterialUpload course={course.course} />
            <hr /></>) : <></>}
        <MaterialWidget course={course.course} />
    </>);
}

export { CourseFilesWidget }